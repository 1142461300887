.right-component {
    float: right;
}
.left-component {
    float: left;
}

.container {
    overflow: auto;
    margin-bottom: 20px;
}

.text-spacing{
    padding-right: 10px;
}