.custom-layout {
  display: flex;
  box-sizing: vertical;
  flex-direction: normal;
  flex-flow: row;
  align-items: flex-start;
}

.custom-col-nav {
  color: grey;
  display: inline-block;
  width: 20rem;
  margin-right: 8rem;
}

.custom-wizard-navigation > li {
  padding-bottom: 1.5rem;
}
.custom-wizard-navigation > li:not(:first-child) {
  margin-top: 1rem;
}
.custom-wizard-navigation > li:not(:last-child) {
  border-bottom: 1px solid grey;
}

.custom-wizard-navigation .active {
  font-weight: bold;
  color: black;
  pointer-events: none;
}

.custom-wizard-navigation .visited {
  color: grey;
  cursor: pointer;
}

.custom-col-content {
  width: 100%;
}
