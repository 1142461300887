.plan-progress-tracker {
  margin: auto;
  padding: 10px;
  padding-left: 35px;
  border-bottom: 1px inset #808080;
}
.plan-progress-tracker-subtitle {
  color: orange;
}

.plan-progress-navigation {
  padding: 10px 25px 25px 25px;
  margin: auto;
}

.plan-progress-feedback {
  margin: 0 40px;
}

.plan-progress-navigation-link:hover {
  text-decoration: none !important;
}

.plan-progress-navigation-section {
  display: inline-flex;
  justify-content: space-between;
  width: 200px;
  padding-bottom: 10px;
  margin: 15px;
  border-bottom: 1px solid #eaeded;
}

.plan-progress-navigation-section-completed {
  color: green;
}
