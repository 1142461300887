.aws-page-header {
  font-family: Amazon Ember, Helvetica Neue, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.aws-page-header header.aws-page-main-header {
  color: white;
  background-color: #37474f;
  border-bottom: 1px solid grey;
  padding-left: 40px;
  padding-right: 40px;
}
.aws-page-header header.aws-page-main-header .page-header-content {
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.aws-page-header header.aws-page-main-header .page-header-content .page-header-left {
  margin-top: 18px;
  margin-bottom: -12px;
  display: inline-flex;
}

.aws-page-header header.aws-page-main-header .page-header-content .page-header-right {
  margin: auto 35px auto 0px;
}

.aws-page-header header.aws-page-main-header .page-header-content .page-header-left img {
  height: 35px;
  width: 59px;
}

.aws-page-header header.aws-page-main-header .page-header-content .page-header-left .aws-header-bpt-div {
  margin-left: 20px;
  height: 35px;
  padding-left: 10px;
  border-left: 2px solid white;
  text-decoration: none;
  color: white;
}

.aws-page-header
  header.aws-page-main-header
  .page-header-content
  .page-header-left
  .aws-header-bpt-div
  .aws-header-bpt {
  font-size: 3rem;
  line-height: 3rem;
  padding-top: 0px;
}


.header-text-title {
  margin-right: 10px !important;
}

.header-text-plans {
  color: #aab7b8 !important;
}
.right-component {
    float: right;
}
.left-component {
    float: left;
}

.container {
    overflow: auto;
    margin-bottom: 20px;
}

.text-spacing{
    padding-right: 10px;
}
.custom-layout {
  display: flex;
  box-sizing: vertical;
  flex-direction: normal;
  flex-flow: row;
  align-items: flex-start;
}

.custom-col-nav {
  color: grey;
  display: inline-block;
  width: 20rem;
  margin-right: 8rem;
}

.custom-wizard-navigation > li {
  padding-bottom: 1.5rem;
}
.custom-wizard-navigation > li:not(:first-child) {
  margin-top: 1rem;
}
.custom-wizard-navigation > li:not(:last-child) {
  border-bottom: 1px solid grey;
}

.custom-wizard-navigation .active {
  font-weight: bold;
  color: black;
  pointer-events: none;
}

.custom-wizard-navigation .visited {
  color: grey;
  cursor: pointer;
}

.custom-col-content {
  width: 100%;
}


.plan-progress-tracker {
  margin: auto;
  padding: 10px;
  padding-left: 35px;
  border-bottom: 1px inset #808080;
}
.plan-progress-tracker-subtitle {
  color: orange;
}

.plan-progress-navigation {
  padding: 10px 25px 25px 25px;
  margin: auto;
}

.plan-progress-feedback {
  margin: 0 40px;
}

.plan-progress-navigation-link:hover {
  text-decoration: none !important;
}

.plan-progress-navigation-section {
  display: inline-flex;
  justify-content: space-between;
  width: 200px;
  padding-bottom: 10px;
  margin: 15px;
  border-bottom: 1px solid #eaeded;
}

.plan-progress-navigation-section-completed {
  color: green;
}


.plan-card {
  width: 275px;
  height: 275px;
  margin: 12px;
}

.plan-card-header-complete {
  background-color: #6aaf35 !important;
  color: white;
}

.plan-card-header-in-progress {
  background-color: #0073bb !important;
  color: white;
}

.plan-card-body {
  height: 130px;
}

.plan-card-body-complete {
  background-color: #6aaf35 !important;
  color: white;
}

.plan-card-body-in-progress {
  background-color: #0073bb !important;
  color: white;
}

.plan-card-footer {
  display: flex;
  justify-content: space-between;
}

.plan-card-footer h6{
  margin: 0px;
}

.plan-card-footer-div {
  margin-top: auto;
  margin-bottom: auto;
}

.plan-card-footer-button {
}

.plan-card-footer-status {
  text-align: right;
}

.plan-card-section-header {
  position: relative;
}
.plan-section-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 900px;
  padding-left: 55px;
  padding-right: 55px;
  margin: auto;
}

.plan-card-header-text {
  display: inline-flex;
}

.plan-card-header-text-title {
  margin-right: 10px !important;
}

.plan-card-header-text-completed {
  color: #aab7b8 !important;
}

.plan-card-container {
  display: flex;
  flex-wrap: wrap;
}

.plan-section-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 900px;
  padding-left: 55px;
  padding-right: 55px;
  margin: auto;
}

.plan-card-header-text {
  display: inline-flex;
}

.plan-card-header-text-title {
  margin-right: 10px !important;
}

.plan-card-header-text-completed {
  color: #aab7b8 !important;
}

.plan-card-container {
  display: flex;
  flex-wrap: wrap;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

