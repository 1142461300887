
.plan-card {
  width: 275px;
  height: 275px;
  margin: 12px;
}

.plan-card-header-complete {
  background-color: #6aaf35 !important;
  color: white;
}

.plan-card-header-in-progress {
  background-color: #0073bb !important;
  color: white;
}

.plan-card-body {
  height: 130px;
}

.plan-card-body-complete {
  background-color: #6aaf35 !important;
  color: white;
}

.plan-card-body-in-progress {
  background-color: #0073bb !important;
  color: white;
}

.plan-card-footer {
  display: flex;
  justify-content: space-between;
}

.plan-card-footer h6{
  margin: 0px;
}

.plan-card-footer-div {
  margin-top: auto;
  margin-bottom: auto;
}

.plan-card-footer-button {
}

.plan-card-footer-status {
  text-align: right;
}

.plan-card-section-header {
  position: relative;
}