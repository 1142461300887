.plan-section-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 900px;
  padding-left: 55px;
  padding-right: 55px;
  margin: auto;
}

.plan-card-header-text {
  display: inline-flex;
}

.plan-card-header-text-title {
  margin-right: 10px !important;
}

.plan-card-header-text-completed {
  color: #aab7b8 !important;
}

.plan-card-container {
  display: flex;
  flex-wrap: wrap;
}

.plan-section-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 900px;
  padding-left: 55px;
  padding-right: 55px;
  margin: auto;
}

.plan-card-header-text {
  display: inline-flex;
}

.plan-card-header-text-title {
  margin-right: 10px !important;
}

.plan-card-header-text-completed {
  color: #aab7b8 !important;
}

.plan-card-container {
  display: flex;
  flex-wrap: wrap;
}
