.aws-page-header {
  font-family: Amazon Ember, Helvetica Neue, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.aws-page-header header.aws-page-main-header {
  color: white;
  background-color: #37474f;
  border-bottom: 1px solid grey;
  padding-left: 40px;
  padding-right: 40px;
}
.aws-page-header header.aws-page-main-header .page-header-content {
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.aws-page-header header.aws-page-main-header .page-header-content .page-header-left {
  margin-top: 18px;
  margin-bottom: -12px;
  display: inline-flex;
}

.aws-page-header header.aws-page-main-header .page-header-content .page-header-right {
  margin: auto 35px auto 0px;
}

.aws-page-header header.aws-page-main-header .page-header-content .page-header-left img {
  height: 35px;
  width: 59px;
}

.aws-page-header header.aws-page-main-header .page-header-content .page-header-left .aws-header-bpt-div {
  margin-left: 20px;
  height: 35px;
  padding-left: 10px;
  border-left: 2px solid white;
  text-decoration: none;
  color: white;
}

.aws-page-header
  header.aws-page-main-header
  .page-header-content
  .page-header-left
  .aws-header-bpt-div
  .aws-header-bpt {
  font-size: 3rem;
  line-height: 3rem;
  padding-top: 0px;
}
